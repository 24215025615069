import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { CurrencyContext } from '../../contexts/CurrencyContext';

export default function RangeSlider({ min, max, value, onChange, filtername }) {
  const [rangeValue, setRangeValue] = useState(
    Array.isArray(value) && value.length === 2 ? value : [min, max]
  );
  const { formatCurrency } = useContext(CurrencyContext);

  // This function updates the local state as the slider is dragged
  const handleChange = (event, newValue) => {
    setRangeValue(newValue);
  };

  // This function is called when the user stops dragging the slider,
  // or when a click on the slider commits a new value.
  // It updates the parent component with the final value.
  const handleCommit = (event, newValue) => {
    onChange(newValue); // Update the parent component with the final value
  };

  // Update local state if props.value changes
  useEffect(() => {
    if (Array.isArray(value) && value.length === 2) {
      setRangeValue(value);
    }
  }, [value]);

  // Update local state if min or max props change
  useEffect(() => {
    setRangeValue([min, max]);
  }, [min, max]);

  return (
    <div className="flex flex-col justify-center items-center w-[95%]">
      <Slider
        getAriaLabel={() => 'Range Filter'}
        value={rangeValue}
        onChange={handleChange} // Temporarily update local state for visual feedback
        onChangeCommitted={handleCommit} // Commit the final value to the parent component
        valueLabelDisplay="auto"
        min={min}
        max={max}
        getAriaValueText={(value) => `${value}`}
      />

      <div className="w-full flex justify-between mt-2 text-primary">
        <span className="text-sm lg:text-base">
          Min: {filtername === 'price' ? formatCurrency(rangeValue[0]) : rangeValue[0]}
        </span>
        <span className="text-sm lg:text-base">
          Max: {filtername === 'price' ? formatCurrency(rangeValue[1]) : rangeValue[1]}
        </span>
      </div>
    </div>
  );
}
