import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { min } from 'lodash';
/**
 * Pagination component for displaying and controlling page navigation.
 * 
 * This component uses ReactPaginate to handle pagination of a large list of items.
 * It adjusts the number of pages displayed based on the window width for better responsiveness.
 * 
 * @param {number} pageCount - The total number of pages.
 * @param {Function} onPageChange - Callback function triggered when the page is changed.
 * @param {number} totalProducts - The total number of products/items.
 * @param {number} quantity - The number of items per page.
 * @param {number} currentPage - The current active page.
 */
export default function Pagination({ pageCount, onPageChange,totalProducts,quantity,currentPage }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State to track window width

  // Effect to update windowWidth state on window resize
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Adjusting the number of pages displayed based on window width
  let marginPagesDisplayed = 2;
  let pageRangeDisplayed = 5;

  if (windowWidth <= 768) {  // breakpoint for mobile screens
    marginPagesDisplayed = 1;
    pageRangeDisplayed = 3;
  }

  // JSX for the Pagination component
    return (
        <div className="items-center justify-between lg:flex">
           <div className="text-lg font-semibold text-primary mt-5">
                Showing {((currentPage - 1) * quantity) + 1} - 
                {Math.min(currentPage * quantity, totalProducts)} of {totalProducts} Products
            </div>
            <div className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">

            <ReactPaginate 
      previousLabel={'\u003c'} // Unicode for left arrow
      nextLabel={'\u003e'} // Unicode for right arrow
      breakLabel={"..."} 
      breakClassName={"break-me"} 
      pageCount={pageCount} 
      marginPagesDisplayed={marginPagesDisplayed} 
      pageRangeDisplayed={pageRangeDisplayed} 
      onPageChange={onPageChange} // Here's the correction
      containerClassName={"pagination"} 
      subContainerClassName={"pages pagination"} 
      activeClassName={"active"} 
      forcePage={currentPage - 1} // Step 3: Set the initial page

    />


            </div>
        </div>
    )
}
