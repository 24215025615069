import { useState, useEffect, useContext } from 'react';
import Image from 'next/image';
import Link from "next/link";
import { CartContext } from "../../contexts/CartContext";
import { setImageSrc } from '../../utils/config';
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import NoProducts from '../../public/images/noImage.jpeg';
import { useJobId } from '../../contexts/ContextJbo';
import errorLogApi from '../custom/ErrorLogApi';




/**
 * Snackbar component for displaying notifications related to cart actions.
 * 
 * This component shows a snackbar notification when a product is added to the cart.
 * It displays a message and provides links to view the cart or proceed to checkout.
 * 
 * @param {Object} product - The product related to the notification.
 * @param {boolean} isOpen - Flag to control the visibility of the snackbar.
 * @param {Function} setIsOpen - Function to update the isOpen state.
 */
export const ProductImage = ({ product, index, theme, view }) => {

  const [imageLoaded, setImageLoaded] = useState(false);
  const { jboId } = useJobId();
  const [retryCount, setRetryCount] = useState(0);
  // state to get current src
  const [currentSrc, setCurrentSrc] = useState(setImageSrc(product.image, product.name, view === "twoCard" ? 's600' : 's400'));
  const retryDelay = 1000;

  const [hoverRetryCount, setHoverRetryCount] = useState(0);
   // Image Quality issue fixed - 20/08/2024
  const [hoverCurrentSrc, setHoverCurrentSrc] = useState(setImageSrc(product.hoverImage, product.name, view === "twoCard" ? 's600' : 's400'));
  // Function to call when image is loaded
  const handleImageLoaded = (event) => {
    // Directly hide the LoadingAnimation sibling of the img tag
    // event.currentTarget.previousSibling.style.display = 'none';
    setImageLoaded(true);
  };
  useEffect(() => {
    if (retryCount > 0 && retryCount <= 2) {

      const timer = setTimeout(() => {
        setCurrentSrc(`${currentSrc}?retry=${retryCount}`);
      }, retryDelay);

      return () => clearTimeout(timer);
    }else if(retryCount == 3){
      const timer = setTimeout(() => {
        setCurrentSrc(`${NoProducts.src}?retry=${retryCount}`);
        const image = setImageSrc(product.image, product.name, view === "twoCard" ? 's600' : 's400')
  
        errorLogApi({
          job_id: jboId,
          endpoint: 'product list page',
          payload: image,
          responseData: '',
          errorMsg: 'image failed to load'
        });
      }, retryDelay);

      return () => clearTimeout(timer);
    }
  }, [retryCount]);

  const handleError = () => {
    if (retryCount < 3) {
      setRetryCount(retryCount + 1);
    }
  };

  useEffect(() => {
    if (hoverRetryCount > 0 && hoverRetryCount <= 2) {
      
      const timer = setTimeout(() => {
        setHoverCurrentSrc(`${hoverCurrentSrc}?retry=${hoverRetryCount}`);
      }, retryDelay);

      return () => clearTimeout(timer);
    }else if(hoverRetryCount == 3){
      const timer = setTimeout(() => {
        setHoverCurrentSrc(`${NoProducts.src}?retry=${hoverRetryCount}`);
        const hoverImage = setImageSrc(product.hoverImage, product.name, view === "twoCard" ? 's600' : 's400')
   
        errorLogApi({
          job_id: jboId,
          endpoint: 'product list page',
          payload: hoverImage,
          responseData: '',
          errorMsg: 'image failed to load'
        });
      }, retryDelay);

      return () => clearTimeout(timer);
    }
  }, [hoverRetryCount]);

  const handleHoverError = () => {
    if (hoverRetryCount < 3) {
      setHoverRetryCount(hoverRetryCount + 1);
    }
  };

  const handleImageError = async (i, imageURL, id, append) => {
 
    try {
      let retryFlag = 0
      for (let i = 0; i < 2; i++) {
        let img = await fetch(imageURL)

        if (img.status === 403 || img.status === 404 || img.status === 504 || img.status === 502) {
        
          retryFlag++
          continue
        }
        else if (img.status === 200) {
          // setSuccessfulImages(prevImages => ({ ...prevImages, [i]: imageURL }));
          const img1 = document.getElementById(id + append);
          if (img1) {
            img1.src = imageURL;
          } else {
            console.error(`Element with ID ${id} not found.`);
          }
          break;
        }
      }
      if (retryFlag == 2) {
       
        // setFailedImageIndexes(prevIndexes => [...prevIndexes, i]);
        const img1 = document.getElementById(id + append);
        if (img1) {
          img1.src = NoProducts.src;

        } else {
          console.error(`Element with ID ${id} not found.`);
        }
        errorLogApi({
          job_id: jboId,
          endpoint: 'product list page',
          payload: imageURL,
          responseData: '',
          errorMsg: 'image failed to load'
        });
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };
  // JSX for the productImage component
  return (
    <>
    <div className="relative mb-4 group cursor-pointer">
  {/* Loading animation stretch issue fix - 23-10-2024 */}
  {!imageLoaded && (
    <div className="absolute inset-0 flex items-center justify-center">
      <LoadingAnimation />
    </div>
  )}

{/* Gap issue and image clarity issue fixed - 13-08-2024 */}
      <Link href={`${product.url}${theme.siteStatus === 0 ? `?pid=${theme.token}` : ''}`} target="_blank">
  <div className="relative w-full h-full cursor-pointer group">
    {/* Main Image */}
 <img
      key={index}
      onLoad={handleImageLoaded}
      onError={handleError}
      // Image Quality issue fixed - 20/08/2024
      src={setImageSrc(product.image, product.name, `${view == "twoCard" ? "s800" : "s500"}`)}
      alt={product.name}
      width={500}
      height={500}
      className="w-full h-full transition-opacity duration-500"

    /> 
  

    {/* Hover Image */}
    {product.hoverImage && (
      <>
   <img
        key={index + product.id}
        onError={handleHoverError}
         // Image Quality issue fixed - 20/08/2024
        src={setImageSrc(product.hoverImage, product.name, `${view == "twoCard" ? "s800" : "s500"}`)}
        alt={product.name}
        width={500}
        height={500}
         // quality modifications
        className="absolute inset-0 w-full h-full transition-opacity duration-500 opacity-0 group-hover:opacity-100"
      
      />
 
      </>
    )}
  </div>
</Link>


      </div>
    </>


  );
};
