import Image from "next/image";
import Link from '../../components/custom/Link';
import { useContext, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import { FaExclamationCircle } from 'react-icons/fa';
import { useRouter } from 'next/router'; 
import {
 
  FaHeartBroken,
  FaMinus,
  FaCheckCircle,
  FaRegHandPointRight,
  FaHeart,
  FaInfoCircle,
  FaCartPlus
} from "react-icons/fa";
import { ThemeContext } from '../../contexts/ThemeContext';
import CustomPopup from '../../components/CustomPopup';
import LoadingAnimation from '../../components/custom/LoadingAnimation';
import { BiHeart } from "react-icons/bi";
import { BsFillBagHeartFill } from "react-icons/bs";
import { CurrencyContext } from "../../contexts/CurrencyContext";
import { CartContext } from "../../contexts/CartContext";
import { UserContext } from '../../contexts/UserContext';
import { WishlistContext } from "../../contexts/WishlistContext";
import "swiper/swiper.min.css";
import { Snackbar } from './Snackbar';
import {NoDataFound} from '../../pages/404';
import NoProducts from '../../public/images/pnf.jpg';
import NoImage from '../../public/images/googlemap.jpeg';
import { setImageSrc } from '../../utils/config';
SwiperCore.use([Navigation]);
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import errorLogApi from '../../components/custom/ErrorLogApi';
import { useJobId } from "../../contexts/ContextJbo";
import { ProductImage } from "./ProductImage";
import MakeOrder from "../../public/images/makeOrder.png"
// empty commit
import InStock from "../../public/images/instock.png"
import DynamicQuantityButton from "../../pages/product/DynamicQuantityButton";

/**
 * ProductsDefault component for displaying products with default layout.
 * 
 * This component handles displaying products, managing wishlist, and adding products to the cart.
 * It includes grid customization based on settings and loading placeholders.
 * 
 * @param {Array} products - Array of products to display.
 * @param {boolean} isLoading - Loading state for showing placeholders.
 * @param {boolean} isSpecificGrid - Flag to determine grid layout.
 * @param {Object} view - View settings for the product display.
 * @param {Object} productSettings - Settings related to product display.
 */
function ProductsDefault({ products, isLoading, isSpecificGrid, view, productSettings, filterLoading }) {

  console.log("products data",products);
  const router = useRouter(); // Hook to access router object
  const { token, userDetails } = useContext(UserContext); // User context for auth and details
  const { formatCurrency } = useContext(CurrencyContext); // Currency context for formatting
  const { cart, addToCart, updateQuantity, isSnackbarVisible } = useContext(CartContext); // Cart context
  const { wishlist, addToWishlist, removeFromWishlist } = useContext(WishlistContext); // Wishlist context
 
  // State for snackbar, wishlist status, and grid layout
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProduct, setSnackbarProduct] = useState(null);
  const [wishlistStatus, setWishlistStatus] = useState(
    products.reduce((acc, product) => ({ ...acc, [product.id]: "not_added" }), {})
  );
  const [retryCount, setRetryCount] = useState(0);
  const [failedImageUrl, setFailedImageUrl] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupConfig, setPopupConfig] = useState({});
  const userType = userDetails?.user_type || 0;

  // Calculate grid layout based on product settings
  const gridClasses = isSpecificGrid
    ? "grid lg:grid-cols-3 xl:grid-cols-4 sm:grid-cols-2 gap-6"
    : "grid lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2 gap-6";
  const theme = useContext(ThemeContext);

  const [isRemoved, setIsRemoved] = useState(false);
  const isWishlisted = (product) => wishlistStatus[product.id] !== "not_added";

   // wishlist modification - 16-08-2024
const isProductInWishlist = (productId) => {
 

  // Normalize the given productId to string for comparison
  const normalizedProductId = String(productId);

  const found = wishlist.some((item) => {
    const normalizedProductId1 = String(item.product_id1);
    const normalizedItemProductId = String(item.product_id);

    

    if (
      normalizedItemProductId === normalizedProductId ||
      normalizedProductId1 === normalizedProductId
    ) {
    
      return true;
    }

    return false;
  });

 

  return found;
};

  // Toggle wishlist status
  const toggleWishlist = async (product, event) => {
    event.preventDefault();
    if (!userDetails) {
      const url = window.location.href;
      document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;

      router.push('/auth/SignIn');
    } else {

      if (isProductInWishlist(product.id || product.product_id1)) {
        await removeFromWishlist(product);
        setWishlistStatus(prevStatus => ({ ...prevStatus, [product.id]: "removed" }));
      } else {
        await addToWishlist(product);
        setWishlistStatus(prevStatus => ({ ...prevStatus, [product.id]: "added" }));
      }
    }
  };


  // Update wishlist status based on wishlist data
  useEffect(() => {
    const newWishlistStatus = products.reduce((acc, product) => {
      const wishlistItem = wishlist.find(
        (item) => item.product_id === product.id
      );
      if (wishlistItem) {
        return { ...acc, [product.id]: "added" };
      }
      return { ...acc, [product.id]: "not_added" };
    }, {});

    setWishlistStatus(newWishlistStatus);
  }, [products, wishlist]);

  const handleAddToCart = (product, event) => {
    if (!userDetails) {
      const url = window.location.href;
      document.cookie = `initialReferrer=${encodeURIComponent(url)}; path=/`;

      router.push('/auth/SignIn');
    } else {
      event.preventDefault();
      addToCart(product);
      setSnackbarProduct(product);
      setSnackbarOpen(true);
    }
  };

  const handleDisabledClick = (event) => {
    event.preventDefault();
    setPopupConfig({
      type: 'info',
      title: 'Preview Mode',
      subText: 'Actions like "Add to Cart" and "Wishlist" are disabled during preview.',
      onConfirm: closePopup,
      onClose: closePopup,
      showConfirmButton:false,
        showCancelButton:false,
      autoClose: 3,
    });
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // Function to truncate string length
  function truncate(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num > 3 ? num - 3 : num) + '...';
  }

  // Function to calculate grid column class based on settings
  const gridColumnClass = () => {
    const defaultGridColumn = 'xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1';
    const count = {
      twoCard: 2,
      grid:3,
      fourCard: 4
    }[view] || productSettings?.display_products_count || 1;

    return {
      2: 'md:grid-cols-2',
      3: 'lg:grid-cols-3',
      4: 'lg:grid-cols-4',
    }[count] || defaultGridColumn;
  };

  useEffect(() => {
    const newWishlistStatus = products.reduce((acc, product) => {
      const wishlistItem = wishlist.find(
        (item) => item.product_id === product.id
      );
      if (wishlistItem) {
        return { ...acc, [product.id]: "added" };
      }
      return { ...acc, [product.id]: "not_added" };
    }, {});

    setWishlistStatus(newWishlistStatus);
  }, [wishlist]);

  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true);
  const [isLoadingMainImage, setIsLoadingMainImage] = useState(true);
  const [isLoadingHoverImage, setIsLoadingHoverImage] = useState(true);
  const [failedImageIndexes, setFailedImageIndexes] = useState([]);
  const [successfulImages, setSuccessfulImages] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);

  // Function to call when image is loaded
  const handleImageLoaded = (event) => {
    setImageLoaded(true);
  };

  const handleImageError = async (i, imageURL, id) => {
    
    try {
      let retryFlag = 0
      for (let i = 0; i < 2; i++) {
        let img = await fetch(imageURL)

        if (img.status === 403 || img.status === 404) {
       
          retryFlag++
          continue
        }
        else if (img.status === 200) {
          setSuccessfulImages(prevImages => ({ ...prevImages, [i]: imageURL }));
          const img1 = document.getElementById(id + '_main');
          if (img1) {
            img1.src = imageURL;
          } else {
         
          }
          break;
        }
      }
      if (retryFlag == 2) {

        setFailedImageIndexes(prevIndexes => [...prevIndexes, i]);
        errorLogApi({
          job_id: jboId,
          endpoint: 'product list page',
          payload: imageURL,
          responseData: '',
          errorMsg: 'image failed to load'
        });
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#005023',
      boxShadow: theme.shadows[1],
      fontSize: 13,
      fontWeight: '600'
    },
  }));

  // Skeleton loader for loading state
  if (!filterLoading && isLoading) {
    return (
      <LoadingAnimation />
    );
  }
  else if (products.length === 0 && !filterLoading) {
    // Show "no products" image only after loading has finished
    return (
      <>
       <NoDataFound/>
      </>
    );
  } else {
    return (
      <>
        {!filterLoading && <div className={`grid ${view === 'list' ? 'lg:grid-cols-1 md:grid-cols-2 gap-6' : `grid ${gridColumnClass()} md:grid-cols-2 grid-cols-1`} `}>
          {products.map((product, i) => {
        
        
          // Ensure  both IDs  are converted to strings before comparison
          const isInCart = cart?.some((item) => {
         
            const isMatch = String(item.product_id) === String(product.id);
          
            return isMatch;
          });
        
          const isInWishlist = wishlist?.some((item) => {
            // Normalize and compare both product_id and product_id1 with product.id
            const isMatch =
              String(item.product_id) === String(product.id) ||
              String(item.product_id1) === String(product.id);
          
            return isMatch;
          });

        
       
            if (view === 'list') {
              return (
                <div key={product.id} className={`w-full cursor-pointer ${(userDetails?.user_type === 2 || userDetails?.user_type === 3) ? 'xl:h-[20rem]' : 'xl:h-[18rem]'} md:h-[18rem] h-full flex`}>
                  <div className="rounded overflow-hidden shadow-lg cursor-pointer bg-white w-[50%] lg:w-[26%] ">
                    <ProductImage product={product} index={i} theme={theme} view={view} />
                  </div>
                  <div className="rounded overflow-hidden shadow-lg bg-white w-1/2 p-4">
                    <Link href={`${product.url}${theme.siteStatus === 0 ? `?pid=${theme.token}` : ''}`} target="_blank">
                      <h2 className="text-xl text-primary font-bold mb-2 whitespace-nowrap overflow-hidden overflow-ellipsis">{product.name}</h2>
                    </Link>
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 items-start sm:items-center text-lg mb-2">
                   {/* Price rule Added 04/09/24 */}
                    <h3 className="mb-2 ">
                  {
                    ((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))? (
                      <>
                         <span className="text-green-700 text-xl xl:text-xl ">{formatCurrency(product.specialPrice)} </span>

                        <span className="ml-1 line-through text-red-500 text-lg">{formatCurrency(product.price)} </span>
                        <span className=" text-md font-semibold text-green-700 mb-2 ">{product.priceRuleDisplay}</span>    

                      </>

                    ) : (<span>{formatCurrency(product.price)} </span>)
                  }
                </h3>                       </div>
                    {product.Availability === 1 ? (
                      <div className="flex items-center text-green-600 pb-2">
                           <Image src={InStock} alt="Make Order" width={40} height={40} layout="intrinsic" className="mr-2"/>
                        <span className="font-semibold">In Stock</span>
                      </div>
                    ) : (
                      <div className="flex items-center text-yellow-600 pb-2 hover:text-yellow-700 transition-colors duration-300">
                        <Image src={MakeOrder} alt="Make Order" width={40} height={40} layout="intrinsic" className="mr-2"/>
                        <span className="font-semibold ">Make Order</span>
                      </div>
                    )}
                    {
                      parseFloat(product.weight) > 0 && productSettings.display_product_weight == 1 ? (
                        <h3 className="font-semibold text-lg mb-2 text-gray-600">Weight: {product.weight} Gms</h3>
                      ) : null
                    }
                    {
                      (product.touch !== null && product.touch != 0 && product.touch != undefined) && userDetails && (userDetails?.user_type === 2 || userDetails?.user_type === 3) ? (
                        <h3 className="font-semibold text-lg mb-2 text-alternative">Touch : {product.touch}</h3>
                      ) : null
                    }
                    {
                      userDetails && product.tagLabel && (userDetails?.user_type === 0 || userDetails?.user_type === 1) ? (
                        <span className="font-semibold text-lg mb-2 text-alternative">Tag label: {product.tagLabel}</span>
                      ) : null
                    }<br />
                    {
                      userDetails && product.subItem && (userDetails?.user_type === 0 || userDetails?.user_type === 1) ? (
                        <span className="font-semibold text-lg mb-2 text-alternative">Sub item : {product.subItem}</span>
                      ) : null
                    }
                    <div className="flex gap-5">
                      <button
                        className="bg-primary hover:bg-gray-800 text-white text-lg w-10 h-10 rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110 cursor-pointer"
                        onClick={(event) => theme.siteStatus !== 0 ? toggleWishlist(product, event) : handleDisabledClick(event)}
                      >
                        <div className={`${isInWishlist ? "animate-pulse" : ""}`}>
    {isInWishlist ? (
      <FaHeart size={24} />
    ) : (
      <BiHeart
        size={24}
        className="text-white stroke-current stroke-3 animate-pulse"
      />
    )}
  </div>
                      </button>
                      {product?.mProductId?.toString().startsWith('m') ? <DynamicQuantityButton product={product} uiMode="1" isCart={false} /> :   <>
                      {isInCart ?
                      
                        <Link
                          href={`${product.url}${theme.siteStatus === 0 ? `?pid=${theme.token}` : ''}`}
                          className="bg-primary hover:bg-alternative text-white w-10 h-10 rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110"
                        >
                          <FaInfoCircle size={20} />
                        </Link>
                        :
                        <button
                          onClick={(event) => theme.siteStatus !== 0 ? handleAddToCart(product, event) : handleDisabledClick(event)}
                          className="bg-primary hover:bg-alternative text-white w-10 h-10 rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110"
                        >
                          <BsFillBagHeartFill size={20} />
                        </button>
                      }
                      </>}
                    </div>
                  </div>
                </div>
              );
            }
            else {
              return (
                <div key={product.id}>
                  <div className="bg-white sm:p-2 sm:m-2 p-4 shadow-sm rounded-xl h-full max-w-screen-md m-4 ">
                    <ProductImage product={product} index={i} theme={theme} view={view} />
                    <div className="flex justify-between items-center relative">
                      <div className="text-left flex-grow" target="_blank">
                        {product.name !== null ? (
                          <Link href={`${product.url}${theme.siteStatus === 0 ? `?pid=${theme.token}` : ''}`} style={{ height: gridColumnClass() === 'md:grid-cols-2' ? 'auto' : '4rem' }} target="_blank">
                            <h2
                              style={{
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                              }}
                              title={product.name}
                              className={`font-semibold text-lg mb-2 text-primary break-words ${
                                gridColumnClass() === 'md:grid-cols-2' ? 'text-xl' : 'text-lg'
                              }`}
                            >
                              {product.name}
                            </h2>
                          </Link>
                        ) : false}
                        {/* Price rule Added 04/09/24 */}
        <h3 className="mb-2 ">
                  {
                    ((product.isSpecialPrice == 1) && (userType == 1 || userType == 0))? (
                      <>
                         <span className="text-green-700 text-xl xl:text-xl ">{formatCurrency(product.specialPrice)} </span>

                        <span className="ml-1 line-through text-red-500 text-lg">{formatCurrency(product.price)} </span><br></br>
                        <span className=" text-md font-semibold text-green-700 mb-2 ">{product.priceRuleDisplay}</span>    

                      </>

                    ) : (<span>{formatCurrency(product.price)} </span>)
                  }
                </h3>                           {/* Product setting modifications - 16-08-2024 */}
                        {productSettings && productSettings.display_product_weight == 1 && parseFloat(product.weight) > 0 ? (
        <h3 className="font-semibold text-lg lg:text-md xl:text-lg mb-2 text-alternative">
          Weight: {product.weight} Gms
        </h3>
      ) : null}
                        {
                          (product.touch !== null && product.touch != 0 && product.touch != undefined) && userDetails && (userDetails?.user_type === 2 || userDetails?.user_type === 3) ? (
                            <h3 className="font-semibold text-lg lg:text-md xl:text-lg mb-2 text-alternative">Touch : {product.touch}</h3>
                          ) : null
                        }
                        <div className="flex justify-between items-center">
                          {product.Availability === 1 ? (
                            <a className="flex items-center text-green-600 lg:text-sm">
                              {gridColumnClass() == 'lg:grid-cols-3' ? (
                                <div className="flex items-center">
                               <Image src={InStock} alt="Make Order" width={40} height={40} layout="intrinsic" className="mr-2"/>
                                  <span className="font-semibold xl:text-lg">In Stock</span>
                                </div>
                              ) : (
                                <div className="flex items-center">
                                 <Image src={InStock} alt="Make Order" width={40} height={40} layout="intrinsic" className="mr-2"/>
                                  <span className="font-semibold xl:text-lg">In Stock</span>
                                </div>
                              )}
                            </a>
                          ) : (
                            <a className="flex items-center text-yellow-600 hover:text-yellow-700 transition-colors duration-300 lg:text-sm">
                            {gridColumnClass() == 'lg:grid-cols-3' ? (
                              <div className="flex items-center">
                                <Image src={MakeOrder} alt="Make Order" width={40} height={40} layout="intrinsic" className="mr-2"/>
                                <span className="font-semibold lg:text-md xl:text-lg">Make Order</span>
                              </div>
                            ) : (
                              <div className="flex items-center">
                                <Image src={MakeOrder} alt="Make Order"width={40} height={40} layout="intrinsic" className="mr-2" />
                                <span className="font-semibold lg:text-md xl:text-lg">Make Order</span>
                              </div>
                            )}
                          </a>
                          
                          )}
                          {gridColumnClass() != 'lg:grid-cols-4' && (
                            <div className="flex row">
                              <div className="mx-1 flex flex-col items-center bottom-0 right-0">
                                <button
                                  onClick={(event) => theme.siteStatus !== 0 ? toggleWishlist(product, event) : handleDisabledClick(event)}
                                  className={`bg-primary hover:bg-gray-800 text-white text-lg ${gridColumnClass() == 'lg:grid-cols-3' ? ' w-10 h-10' : gridColumnClass() == 'lg:grid-cols-4' ? 'w-8 h-8' : 'w-10 h-10' } rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110 cursor-pointer`}
                                >
                                 <div className={`${isInWishlist ? "animate-pulse" : ""}`}>
  {isInWishlist ? (
    gridColumnClass() === 'lg:grid-cols-3' ? (
      <FaHeart size={20} />
    ) : gridColumnClass() === 'lg:grid-cols-4' ? (
      <FaHeart size={15} />
    ) : (
      <FaHeart size={20} />
    )
  ) : (
    gridColumnClass() === 'lg:grid-cols-3' ? (
      <BiHeart size={20} className="text-white stroke-current stroke-3 animate-pulse" />
    ) : (
      <BiHeart size={20} className="text-white stroke-current stroke-3 animate-pulse" />
    )
  )}
</div>

                                </button>
                              </div>
                              <div className={`mx-1 flex flex-col items-center bottom-0 right-0`}>
                                <div className="relative group">
                                  {/* model based quantity button */}
                                {product?.mProductId?.toString().startsWith('m') ? <DynamicQuantityButton product={product} uiMode="1" isCart={false} /> :   <LightTooltip title={isInCart ? "Product details" : "Add to cart"} placement="top" arrow>
                                    {isInCart ?
                                      <Link
                                        href={product.url}
                                        className={`bg-primary hover:bg-alternative text-white ${gridColumnClass() == 'lg:grid-cols-3' ? 'w-10 h-10' : gridColumnClass() == 'lg:grid-cols-4' ? 'w-8 h-8' : 'w-10 h-10' } rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110`}
                                      >
                                        {gridColumnClass() == 'lg:grid-cols-3' ? (<FaInfoCircle size={20} />) : gridColumnClass() == 'lg:grid-cols-4' ? <FaInfoCircle size={15} /> : (<FaInfoCircle size={20} />)}
                                      </Link>
                                      :
                                      <button
                                        onClick={(event) => theme.siteStatus !== 0 ? handleAddToCart(product, event) : handleDisabledClick(event)}
                                        className={`bg-primary hover:bg-primary text-white ${gridColumnClass() === 'lg:grid-cols-3' ? ' w-10 h-10' : gridColumnClass() === 'lg:grid-cols-4' ? 'w-8 h-8' : 'w-10 h-10' } rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110 z-20`}
                                      >
                                        {gridColumnClass() === 'lg:grid-cols-3' || gridColumnClass() === 'lg:grid-cols-4' ? <FaCartPlus size={15} /> : <FaCartPlus size={20} />}
                                      </button>
                                    }
                                  </LightTooltip>}

                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {gridColumnClass() == 'lg:grid-cols-4' && <div className="flex justify-center mt-2">
                      <div className="flex row justify-center px-8 py-2 opacity-100 static bg-white rounded-lg shadow-sm">
                        <div className="mx-2 flex flex-col items-center bottom-0">
                          <button
                            onClick={(event) => theme.siteStatus !== 0 ? toggleWishlist(product, event) : handleDisabledClick(event)}
                            className={`bg-primary hover:bg-gray-800 text-white text-lg ${gridColumnClass() == 'lg:grid-cols-3' ? ' w-10 h-10' : gridColumnClass() == 'lg:grid-cols-4' ? 'w-8 h-8' : 'w-10 h-10' } rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110 cursor-pointer`}
                          >
                           <div className={`${isInWishlist ? "animate-pulse" : ""}`}>
  {isInWishlist ? (
    gridColumnClass() === 'lg:grid-cols-3' ? (
      <FaHeart size={20} />
    ) : gridColumnClass() === 'lg:grid-cols-4' ? (
      <FaHeart size={15} />
    ) : (
      <FaHeart size={20} />
    )
  ) : (
    gridColumnClass() === 'lg:grid-cols-3' ? (
      <BiHeart size={20} className="text-white stroke-current stroke-3 animate-pulse" />
    ) : (
      <BiHeart size={20} className="text-white stroke-current stroke-3 animate-pulse" />
    )
  )}
</div>

                          </button>
                        </div>
                        <div className={`mx-2 flex flex-col items-center bottom-0`}>
                          <div className="relative group">
                          {product?.mProductId?.toString().startsWith('m') ? <DynamicQuantityButton product={product} uiMode="1" isCart={false} /> :  <LightTooltip title={isInCart ? "Go to cart" : "Add to cart"} placement="top" arrow>
                              {isInCart ?
                                <Link
                                  href={product.url}
                                  className={`bg-primary hover:bg-alternative text-white ${gridColumnClass() == 'lg:grid-cols-3' ? 'w-10 h-10' : gridColumnClass() == 'lg:grid-cols-4' ? 'w-8 h-8' : 'w-10 h-10' } rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110`}
                                >
                                  {gridColumnClass() == 'lg:grid-cols-3' ? (<FaInfoCircle size={20} />) : gridColumnClass() == 'lg:grid-cols-4' ? <FaInfoCircle size={15} /> : (<FaInfoCircle size={20} />)}
                                </Link>
                                :
                                <button
                                  onClick={(event) => theme.siteStatus !== 0 ? handleAddToCart(product, event) : handleDisabledClick(event)}
                                  className={`bg-primary hover:bg-primary text-white ${gridColumnClass() == 'lg:grid-cols-3' ? ' w-10 h-10' : gridColumnClass() == 'lg:grid-cols-4' ? 'w-8 h-8' : 'w-10 h-10' } rounded-full flex items-center justify-center transition transform duration-500 hover:scale-110 z-20`}
                                >
                                  {gridColumnClass() == 'lg:grid-cols-3' ? (<FaCartPlus size={20} />) : gridColumnClass() == 'lg:grid-cols-4' ? <FaCartPlus size={15} /> : (<FaCartPlus size={20} />)}
                                </button>
                              }
                            </LightTooltip>}

                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>
              );
            }
          })}
          {isSnackbarVisible ? <Snackbar product={snackbarProduct} isOpen={snackbarOpen} setIsOpen={setSnackbarOpen} /> : false}
        </div>}
        <CustomPopup
          isOpen={isPopupOpen}
          type={popupConfig.type}
          title={popupConfig.title}
          subText={popupConfig.subText}
          onConfirm={popupConfig.onConfirm}
          onClose={popupConfig.onClose}
          autoClose={popupConfig.autoClose}
          showConfirmButton={popupConfig.showConfirmButton}
        showCancelButton={popupConfig.showCancelButton}
        />
      </>
    );
  }
}

export default ProductsDefault;
